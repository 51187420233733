import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3572675178/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`React Native SDK reference `}<a style={{
        "color": "#111"
      }} className="improve-docs" href="/select/sdks/react-native/reference-v1">{`v1`}</a>{` `}<a style={{
        "marginRight": "auto",
        "borderBottom": "2px solid #0048ff"
      }} className="improve-docs" href="/select/sdks/react-native/reference-v2">{`v2`}</a></h1>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#example"
        }}>{`Example`}</a>{` with all properties set`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#class-fidel"
        }}>{`Fidel class`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#properties"
        }}>{`Properties`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#methods"
        }}>{`Methods`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#callbacks"
        }}>{`Callbacks`}</a></li>
    </ol>
    <h2>{`Example`}</h2>
    <p>{`The following is an example set up with all properties useful for Select Transactions/Loyalty use cases:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Fidel, { ENROLLMENT_RESULT, ERROR } from 'fidel-react-native';
//...

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.configureFidel();
  }

  configureFidel() {
    const myImage = require('./demo_images/your_banner.png');
    const resolveAssetSource = require('react-native/Libraries/Image/resolveAssetSource');
    const resolvedImage = resolveAssetSource(myImage);

    const yourSupportedCountries = [
        Fidel.Country.canada,
        Fidel.Country.ireland,
        Fidel.Country.japan,
        Fidel.Country.norway,
        Fidel.Country.sweden,
        Fidel.Country.unitedArabEmirates,
        Fidel.Country.unitedKingdom, 
        Fidel.Country.unitedStates
    ];

    Fidel.setup ({
      sdkKey: yourSdkKey, // mandatory; make sure to store it securely
      programId: 'Your program ID', // mandatory
      programType: Fidel.ProgramType.transactionSelect, // optional
      options: {
        bannerImage: resolvedImage,
        allowedCountries: yourSupportedCountries,
        defaultSelectedCountry: Fidel.Country.unitedStates,
        supportedCardSchemes: [Fidel.CardScheme.visa],
        metaData: { id: 'your-metadata-id', userId: 1234 },
      },
      consentText: {
        termsAndConditionsUrl: 'https://yourwebsite.com/terms', // mandatory, for some scenarios; please check the reference below.
        companyName: 'Your Company Name', // mandatory
        privacyPolicyUrl: 'https://yourwebsite.com/privacy-policy',
        deleteInstructions: "following our delete instructions",
        programName: 'Your program name',
      },
     }, (result) => {
      switch (result.type) {
        case ENROLLMENT_RESULT:
          console.log("card was enrolled: " + result.enrollmentResult.cardId);
          break;
        case ERROR:
          this.handleError(result.error);
          break;
      }
    });
  }

  onButtonPress = () => {
    Fidel.start();
  }

  handleError = (error) => {
    console.log("Error message: " + error.message);
    switch (error.type) {
      case Fidel.ErrorType.userCanceled:
        console.log("User canceled the process");
        break;
      case Fidel.ErrorType.sdkConfigurationError:
        console.log("Please configure the Fidel SDK correctly");
        break;
      case Fidel.ErrorType.enrollmentError:
        this.handleEnrollmentError(error);
        break;
    }
  }

  handleEnrollmentError = (enrollmentError) => {
    switch (enrollmentError.subtype) {
      case Fidel.EnrollmentErrorType.cardAlreadyExists:
        console.log("This card was already enrolled.");
        break;
      case Fidel.EnrollmentErrorType.invalidProgramId:
        console.log("Please configure Fidel with a valid program ID.");
        break;
      case Fidel.EnrollmentErrorType.invalidSdkKey:
        console.log("Please configure Fidel with a valid SDK Key.");
        break;
      case Fidel.EnrollmentErrorType.inexistentProgram:
        console.log("Please configure Fidel with a valid program ID.");
        break;
      case Fidel.EnrollmentErrorType.unexpected:
        console.log("Unexpected enrollment error occurred.");
        break;
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.welcome}>Fidel React Native SDK example</Text>
        <Text style={styles.instructions}>To get started, tap the button below.</Text>
        <Button
          onPress={this.onButtonPress}
          title="Link a card"
          color="#3846ce"
        />
      </View>
    );
  }
};
`}</code></pre>
    <h2>{`class Fidel`}</h2>
    <p>{`This class is designed as a facade, used to configure the card enrollment process, via many of its `}<a parentName="p" {...{
        "href": "#properties"
      }}>{`static properties`}</a>{`, and start `}<a parentName="p" {...{
        "href": "#methods"
      }}>{`different flows`}</a>{`. It's also the class that provides `}<a parentName="p" {...{
        "href": "#callbacks"
      }}>{`callbacks`}</a>{` that might be useful for your application.`}</p>
    <h2>{`Properties`}</h2>
    <p>{`All the properties are expected to be set once, during the lifecycle of your app, as soon as the application finished launching.`}</p>
    <p>{`As you can see in the example above, all properties are set via the `}<inlineCode parentName="p">{`setup`}</inlineCode>{` function. Some properties are grouped logically in the `}<inlineCode parentName="p">{`consentText`}</inlineCode>{` and `}<inlineCode parentName="p">{`options`}</inlineCode>{` child objects. In this reference we will prefix their names with their respective parent objects.`}</p>
    <h3>{`Mandatory properties`}</h3>
    <p>{`These are properties that must be set correctly. In the case where one of these properties are not set or they are set incorrectly, the SDK will return an error in the `}<a parentName="p" {...{
        "href": "#main-results-callback"
      }}><inlineCode parentName="a">{`main results callback`}</inlineCode></a>{` callback (of type: `}<inlineCode parentName="p">{`Fidel.ErrorType.sdkConfigurationError`}</inlineCode>{`).`}</p>
    <h4>{`sdkKey`}</h4>
    <p>{`Expected type: `}<inlineCode parentName="p">{`string`}</inlineCode></p>
    <p>{`The SDK Key is used to authenticate your Fidel API account. Get it from your Fidel API dashboard -> Account Settings -> SDK Keys section.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Important note: For security reasons, please DO NOT store the SDK Key in your codebase. Follow our `}<a parentName="p" {...{
          "href": "/select/sdks/security-guidelines"
        }}>{`SDK security guide`}</a>{` for detailed recommendations.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Note: If you use a `}<strong parentName="p">{`test SDK Key`}</strong>{`, your users can only enroll `}<a parentName="p" {...{
          "href": "/select/cards/#test-card-numbers"
        }}>{`test card numbers`}</a>{`.`}</p>
    </blockquote>
    <h4>{`programId`}</h4>
    <p>{`Expected type: `}<inlineCode parentName="p">{`string`}</inlineCode></p>
    <p>{`The program ID indicates the Fidel API program in which the cards will be enrolled. Get the program ID by navigating to the Fidel API dashboard -> Programs section -> Click on the ID of the program you want to use. Clicking on it will copy the ID in your pasteboard.`}</p>
    <h4>{`consentText.companyName`}</h4>
    <p>{`Expected type: `}<inlineCode parentName="p">{`string`}</inlineCode></p>
    <p>{`By setting this property we customize the consent text, that the cardholder needs to read and agree with, before enrolling a card.`}</p>
    <p>{`The maximum number of characters allowed for this property is `}<inlineCode parentName="p">{`60`}</inlineCode>{`.`}</p>
    <h4>{`consentText.termsAndConditionsUrl`}</h4>
    <p>{`Expected type: `}<inlineCode parentName="p">{`string`}</inlineCode></p>
    <p>{`You need to set your terms and conditions URL if you would like to:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`support all the countries that Fidel API supports`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`set a specific `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` set of countries AND include US or Canada in it.`}</p>
      </li>
    </ol>
    <p>{`By setting this property we add a link to your Terms & Conditions in the consent text. The cardholder needs to read and agree with your terms, before enrolling a card.`}</p>
    <h3>{`Optional properties`}</h3>
    <h4>{`options.supportedCardSchemes`}</h4>
    <p>{`Expected type: `}<inlineCode parentName="p">{`array`}</inlineCode></p>
    <p>{`Default value: `}<inlineCode parentName="p">{`[Fidel.CardScheme.visa, Fidel.CardScheme.mastercard, Fidel.CardScheme.americanExpress]`}</inlineCode></p>
    <p>{`Sets a list of supported card schemes. If a card scheme is supported, cardholders will be able to enroll their card. If a card scheme is not in the list, then the cardholders will see an error message while typing or pasting the unsupported card number.`}</p>
    <p>{`If you set a `}<inlineCode parentName="p">{`null`}</inlineCode>{` value, you will not be able to start the Fidel SDK enrollment flow. In this case, immediately after attempting to start the flow, you will receive an error in the `}<a parentName="p" {...{
        "href": "#main-results-callback"
      }}><inlineCode parentName="a">{`main results callback`}</inlineCode></a>{` callback (of type: `}<inlineCode parentName="p">{`Fidel.ErrorType.sdkConfigurationError`}</inlineCode>{`).`}</p>
    <h4>{`options.allowedCountries`}</h4>
    <p>{`Expected type: `}<inlineCode parentName="p">{`array`}</inlineCode></p>
    <p>{`Default value: `}<inlineCode parentName="p">{`[Fidel.Country.canada, Fidel.Country.ireland, Fidel.Country.japan, Fidel.Country.norway, Fidel.Country.sweden, Fidel.Country.unitedArabEmirates, Fidel.Country.unitedKingdom, Fidel.Country.unitedStates]`}</inlineCode></p>
    <p>{`Sets the list of countries that cardholders can pick to be the card issuing country. When two or more countries are set, cardholders will be able to select the card issuing country with our country selection UI.`}</p>
    <p>{`If you set a value with only one country, the country selection UI will not be displayed in the card enrollment screen. The country that you set will be considered the card issuing country for all cards enrolled in your Fidel API program using the SDK.`}</p>
    <p>{`If you set an empty value, you will not be able to start the enrollment flow. Instead you will receive an error in the main `}<a parentName="p" {...{
        "href": "#main-callback"
      }}><inlineCode parentName="a">{`callback`}</inlineCode></a>{` (`}<inlineCode parentName="p">{`Fidel.ErrorType.sdkConfigurationError`}</inlineCode>{`), immediately after the attempt to start.`}</p>
    <h4>{`options.defaultSelectedCountry`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`Fidel.Country.unitedKingdom`}</inlineCode></p>
    <p>{`Sets the `}<inlineCode parentName="p">{`Fidel.Country`}</inlineCode>{` that will be selected by default when the user opens the card enrollment screen. If the `}<inlineCode parentName="p">{`defaultSelectedCountry`}</inlineCode>{` is not part of the `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` list, then the first country in the `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` list will be selected.`}</p>
    <h4>{`consentText.deleteInstructions`}</h4>
    <p>{`Expected type: `}<inlineCode parentName="p">{`string`}</inlineCode>{`.`}</p>
    <p>{`Default value: `}<inlineCode parentName="p">{`'going to your account settings'`}</inlineCode></p>
    <p>{`This text informs the cardholder how to opt out of transaction monitoring in your program. It is appended at the end of the consent text. The maximum number of characters allowed for this property is `}<inlineCode parentName="p">{`60`}</inlineCode>{`.`}</p>
    <h4>{`consentText.privacyPolicyUrl`}</h4>
    <p>{`Expected type: `}<inlineCode parentName="p">{`string`}</inlineCode></p>
    <p>{`If you provide a value for this parameter, the card enrollment consent text will include a phrase that will provide the user with more privacy related information at the URL that you provide.`}</p>
    <p>{`When the value of this parameter remains `}<inlineCode parentName="p">{`nil`}</inlineCode>{` no such phrase will be displayed in the card enrollment consent text.`}</p>
    <p>{`If you provide an invalid URL string, you will not be able to start the card enrollment flow. Instead you will receive an error in the `}<a parentName="p" {...{
        "href": "#main-results-callback"
      }}><inlineCode parentName="a">{`main results callback`}</inlineCode></a>{` callback (`}<inlineCode parentName="p">{`Fidel.ErrorType.sdkConfigurationError`}</inlineCode>{`), immediately after attempting to start the card enrollment flow.`}</p>
    <h4>{`options.metaData`}</h4>
    <p>{`Expected type: object.`}</p>
    <p>{`Default value: `}<inlineCode parentName="p">{`undefined`}</inlineCode>{`.`}</p>
    <p>{`This is an object that you can use to associate custom data to an enrolled card.`}</p>
    <p>{`We advise setting an `}<inlineCode parentName="p">{`id`}</inlineCode>{` value for this object. Later, it might be useful for you to use our `}<a parentName="p" {...{
        "href": "https://reference.fidel.uk/reference/list-cards-from-metadata-id"
      }}>{`List Cards from Metadata ID`}</a>{` API Endpoint to query for cards using this ID.`}</p>
    <p>{`Example of meta data that you can set:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`metaData: {
    id: "this-is-the-metadata-id",
    userId: "123",
    customKey: 456
}
`}</code></pre>
    <p>{`You would receive an object equal to this one, after successfully enrolling a card, in the main callback of the SDK.`}</p>
    <h4>{`options.bannerImage`}</h4>
    <p>{`Will display the banner image that you set in this parameter at the top of the card details screen. Your custom asset needs to be resolved in order to be passed to our native module:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const myImage = require('./images/your_banner.png');
const resolveAssetSource = require('react-native/Libraries/Image/resolveAssetSource');
const resolvedImage = resolveAssetSource(myImage);
`}</code></pre>
    <p>{`The banner image will take the device's width, but it has a fixed height of 100 pts.
The image view has an `}<inlineCode parentName="p">{`Aspect Fill`}</inlineCode>{` content mode, which means that the banner image that you set will fill its entire predefined area, while keeping the aspect ratio.`}</p>
    <p>{`For the banner image that you can set, we suggest to use the aspect ratio of the smallest devices that you support. On wider devices, the banner image will be cropped from top and bottom sides. This is because of the `}<inlineCode parentName="p">{`Aspect Fill`}</inlineCode>{` content mode that we set for the image view.`}</p>
    <p>{`If a device that opens the SDK has 320dp in width, the aspect ratio of the image view would be `}<em parentName="p">{`320 : 100`}</em>{`.
If a device that opens the SDK has 475dp in width, the aspect ratio of your banner image would be `}<em parentName="p">{`475 : 100`}</em>{`.`}</p>
    <p>{`You need to provide the image for all screen densities (x1, x2 and x3).`}</p>
    <p>{`Depending on what you want to display in the banner image, you might need to experiment a bit to make sure that nothing important from the image is hidden. The most important information should be displayed in the centre of the banner image.`}</p>
    <h4>{`consentText.programName`}</h4>
    <p>{`Expected type: `}<inlineCode parentName="p">{`string`}</inlineCode></p>
    <p>{`Default value: `}<inlineCode parentName="p">{`'our'`}</inlineCode></p>
    <p>{`This value is used in the consent text when enrolling a card issued in a United States or Canada.`}</p>
    <h4>{`programType`}</h4>
    <p>{`Default value: `}<inlineCode parentName="p">{`Fidel.ProgramType.transactionSelect`}</inlineCode></p>
    <p>{`It specifies the type of program you want to enroll cards into. It also influences the flow that the SDK will show to cardholders when enrolling cards. The other supported value of this property is `}<inlineCode parentName="p">{`Fidel.ProgramType.transactionStream`}</inlineCode>{`, but it is useful only for cards enrolled for Stream applications.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: For your Loyalty application, you don't need to set this property as the default value is the correct one, for your use case.`}</p>
    </blockquote>
    <h4>{`options.thirdPartyVerificationChoice`}</h4>
    <p>{`Expected type: `}<inlineCode parentName="p">{`boolean`}</inlineCode></p>
    <p>{`Default value: `}<inlineCode parentName="p">{`false`}</inlineCode></p>
    <p><em parentName="p">{`Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em>{` `}</p>
    <h2>{`Methods`}</h2>
    <h3>{`Fidel.setup(params, callback)`}</h3>
    <p>{`It sets up all the `}<a parentName="p" {...{
        "href": "#properties"
      }}>{`properties`}</a>{` and `}<a parentName="p" {...{
        "href": "#callbacks"
      }}>{`callbacks`}</a>{`. This function is designed to be called only `}<strong parentName="p">{`once`}</strong>{` during the lifecycle of your app.`}</p>
    <p>{`Parameters:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`params`}</inlineCode>{`: a JavaScript object containing all the `}<a parentName="li" {...{
          "href": "#properties"
        }}>{`properties`}</a>{` and `}<a parentName="li" {...{
          "href": "#callbacks"
        }}>{`callbacks`}</a>{` useful for your application.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`callback`}</inlineCode>{`: the main callback in which you will receive different types of results as received during the card enrollment flow.`}</li>
    </ul>
    <h3>{`Fidel.start()`}</h3>
    <p>{`Starts a card enrollment flow. If you set the `}<inlineCode parentName="p">{`programType`}</inlineCode>{` to:`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`Fidel.ProgramType.transactionStream`}</inlineCode>{`, a verified card enrollment flow will be started, for a Transaction Stream program (usually used by Stream applications).`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`Fidel.ProgramType.transactionSelect`}</inlineCode>{`, a regular card enrollment flow will be started, for your Select Transactions program (usually used by Loyalty applications).`}</li>
    </ol>
    <h3>{`Fidel.verifyCard(params)`}</h3>
    <p><em parentName="p">{`Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em>{` Useful only for cards enrolled in a Transaction Stream program.`}</p>
    <h2>{`Callbacks`}</h2>
    <p>{`The React Native SDK provides the following callbacks:`}</p>
    <h3>{`Main results callback`}</h3>
    <p>{`Will be called when an enrollment process result is available, during the card enrollment process. It can be called multiple times with different types of results. Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Fidel.setup ({
      sdkKey: yourSdkKey, // make sure to store it securely
      programId: 'Your program ID',
      // other properties ...
     }, (result) => {
      switch (result.type) {
        case ENROLLMENT_RESULT:
          console.log("card was enrolled: " + result.enrollmentResult.cardId);
          break;
        case ERROR:
          this.handleError(result.error);
          break;
      }
    });
`}</code></pre>
    <h4>{`Enrollment process results`}</h4>
    <p>{`The results are objects received in the main callback of the SDK after specific enrollment processes finish. Expect the following properties:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`type`}</inlineCode>{`. This property lets you distinguish the type of result that was sent. Possible values: `}<inlineCode parentName="p">{`ENROLLMENT_RESULT`}</inlineCode>{`, `}<inlineCode parentName="p">{`VERIFICATION_RESULT`}</inlineCode>{`(`}<em parentName="p">{`Not useful for Loyalty/Select transactions use cases`}</em>{`), `}<inlineCode parentName="p">{`ERROR`}</inlineCode>{`. The possible values are constants defined in the Fidel SDK module: `}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`import Fidel, { ENROLLMENT_RESULT, ERROR } from 'fidel-react-native';`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`enrollmentResult`}</inlineCode>{`. An object that is defined only when the `}<inlineCode parentName="p">{`type`}</inlineCode>{` property is `}<inlineCode parentName="p">{`ENROLLMENT_RESULT`}</inlineCode>{`. Please check this object's properties below.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`error`}</inlineCode>{`. An object that is defined only when the `}<inlineCode parentName="p">{`type`}</inlineCode>{` property is `}<inlineCode parentName="p">{`ERROR`}</inlineCode>{`. Please check this object's properties below.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`verificationResult`}</inlineCode>{`. `}<em parentName="p">{`Not useful for Loyalty/Select transactions use cases`}</em></p>
      </li>
    </ul>
    <h4>{`Enrollment result object`}</h4>
    <p>{`A result that can be received via the main results callback, after a card is successfully enrolled in your Fidel API program.`}</p>
    <p>{`Properties:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`cardId`}</inlineCode>{`: The identifier of the card enrolled with your Fidel API program.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`accountId`}</inlineCode>{`: The Fidel API account identifier.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`programId`}</inlineCode>{`: The identifier of the program that the card was enrolled into.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`enrollmentDate`}</inlineCode>{`: The date when the card was enrolled.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardScheme`}</inlineCode>{`: The enrolled card's scheme.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`isLive`}</inlineCode>{`: This property will be `}<inlineCode parentName="li">{`true`}</inlineCode>{` when your Fidel API account is live and the card was enrolled in your `}<inlineCode parentName="li">{`live`}</inlineCode>{` Fidel API program. If the program that you enrolled the card into is not a `}<inlineCode parentName="li">{`live`}</inlineCode>{` one, then this property will be `}<inlineCode parentName="li">{`false`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardFirstNumbers`}</inlineCode>{`: If available, this property will be populated with the first 6 numbers of the enrolled card. To turn on or off receiving these numbers, please check your Fidel API account's settings.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardLastNumbers`}</inlineCode>{`: If available, this property will be populated with the last 4 numbers of the enrolled card. To turn on or off receiving these numbers, please check your Fidel API account's settings.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardExpirationYear`}</inlineCode>{`: The expiration year of the enrolled card. The values are four digit year values (ex: 2031), `}<strong parentName="li">{`not`}</strong>{` shortened, two digit values (ex: 31).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardExpirationMonth`}</inlineCode>{`: The expiration month of the enrolled card. The values start with `}<inlineCode parentName="li">{`1`}</inlineCode>{` (January) and end with `}<inlineCode parentName="li">{`12`}</inlineCode>{` (December).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`cardIssuingCountry`}</inlineCode>{`: The country where the enrolled card was issued.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`metaData`}</inlineCode>{`: Custom data assigned to the enrolled card via the `}<a parentName="li" {...{
          "href": "#metadata-string-any"
        }}><inlineCode parentName="a">{`metaData`}</inlineCode></a>{` SDK property.`}</li>
    </ul>
    <h4>{`Error result object`}</h4>
    <p>{`An error can occur during the card enrollment process. You can handle it via the main results callback of the SDK.`}</p>
    <p>{`Properties:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`message`}</inlineCode>{`: An error message explaining more details about the error. It is not localized.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`date`}</inlineCode>{`: A `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#the_epoch_timestamps_and_invalid_date"
        }}>{`timestamp`}</a>{` representing when the error occurred.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`type`}</inlineCode>{`: The type of the error. See more details about error types below.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`subtype`}</inlineCode>{`: The subtype of the error. See more details about error subtypes below.`}</li>
    </ul>
    <h4>{`Error types`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.ErrorType.sdkConfigurationError`}</inlineCode>{`: The SDK `}<a parentName="li" {...{
          "href": "#properties"
        }}>{`properties`}</a>{` configuration is incorrect or incomplete. You can receive this error as soon as you attempt to start a flow using the SDK `}<a parentName="li" {...{
          "href": "#methods"
        }}>{`methods`}</a>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.ErrorType.userCanceled`}</inlineCode>{`: The user canceled the card enrollment flow at any stage.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.ErrorType.deviceNotSecure`}</inlineCode>{`: The device that the SDK is running on is not secure (for example, when it is jailbroken/rooted).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.ErrorType.enrollmentError`}</inlineCode>{`. An error type that is received when card enrollment or consent creation fail. Check all the possible error `}<inlineCode parentName="li">{`subtype`}</inlineCode>{`s below.`}</li>
    </ul>
    <h4>{`Enrollment error subtypes`}</h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.EnrollmentErrorType.cardAlreadyExists`}</inlineCode>{`: The card was already enrolled in your Fidel API program. This error is equivalent to the Fidel API error with the code `}<inlineCode parentName="li">{`map.already.exists`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.EnrollmentErrorType.invalidProgramId`}</inlineCode>{`: The program ID used to configure the SDK is not valid. If you receive this error, please make sure that you set a valid program ID via the `}<inlineCode parentName="li">{`programId`}</inlineCode>{` property. This error is equivalent to the Fidel API error with the code `}<inlineCode parentName="li">{`map.already.exists`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.EnrollmentErrorType.invalidSdkKey`}</inlineCode>{`: The SDK Key used to configure the Fidel SDK is not valid. If you receive this error, please make sure that you set a valid SDK Key via the `}<inlineCode parentName="li">{`sdkKey`}</inlineCode>{` property. This error is equivalent to the Fidel API error with the code `}<inlineCode parentName="li">{`credential`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.EnrollmentErrorType.inexistentProgram`}</inlineCode>{`: The program ID used to configure the Fidel SDK is of a program that does not exist. If you receive this error, please make sure that you set the correct program ID via the `}<inlineCode parentName="li">{`programId`}</inlineCode>{` property. This error is equivalent to the Fidel API error with the code `}<inlineCode parentName="li">{`item-exists`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.EnrollmentErrorType.unauthorized`}</inlineCode>{`: The card enrollment process is not authorized. This error is equivalent to the Fidel API error with the code `}<inlineCode parentName="li">{`Unauthorized`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Fidel.EnrollmentErrorType.unexpected`}</inlineCode>{`: An unexpected error during the card enrollment step.`}</li>
    </ul>
    <h3>{`onCardVerificationStarted `}<em parentName="h3">{`(!Experimental)`}</em></h3>
    <p><em parentName="p">{`Not useful for Loyalty/Select Transactions use cases, at the moment.__Not useful for Loyalty/Select Transactions use cases, at the moment.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      